mapboxgl.accessToken = 'pk.eyJ1Ijoia3RpbSIsImEiOiJ4SW0zUTRzIn0.O2NZ8hw_wTvHTF7CeNJFdw';

$(document).ready(function(){
  map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/ktim/ckuwryrsbpzns17o40189kyn2',
    projection: {
      name: 'globe',
      center: [11.55, 55.4],
      parallels: [51.6, 59.2]
    }
  });
  
  
  map.addControl(new mapboxgl.ScaleControl({
    maxWidth: 150
  }),'top-right');
  map.addControl(new mapboxgl.NavigationControl());
  
  const elements = document.querySelectorAll('.mdc-card__primary-action');
  elements.forEach(element => mdc.ripple.MDCRipple.attachTo(element));
  
  flyTo(8.94, 51.76, 14.36, 59.15);
  $("#map-action__2019-hallandsleden").on("click", function() {
    flyTo(12.048, 57.620, 12.387, 57.267);
    setFilter("2019-hallandsleden");
  });
  $("#map-action__2019-bohusleden").on("click", function() {
    flyTo(12.034, 57.612, 12.188, 57.925);
    setFilter("2019-bohusleden");
  });
  $("#map-action__2020-skaneleden").on("click", function() {
    flyTo(13.640, 55.554, 14.357, 55.910);
    setFilter("2020-skaneleden");
  });
  $("#map-action__2020-bohusleden").on("click", function() {
    flyTo(11.919, 57.924, 12.047, 58.368);
    setFilter("2020-bohusleden");
  });
  $("#map-action__2021-skagerak").on("click", function() {
    flyTo(10.989, 57.681, 12.176, 58.951);
    setFilter("2021-bohuskusten");
  });
  const popup = new mapboxgl.Popup({
closeButton: false,
closeOnClick: false,
anchor: 'left',
});
  map.on('mouseenter', 'hiking-route', (e) => {
    const description = e.features[0].properties.text;
    if(description) {
      map.getCanvas().style.cursor = 'pointer';
      popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
    }
  });
  
//   map.on('mouseenter', 'route-logos', (e) => {
//     map.setLayoutProperty('route-logos', 'icon-size', [
//   "match",
//   ["id"],
//   [e.features[0].id],
//   1,
//   0.75
// ]);
//   });
//   
//   map.on('mouseleave', 'route-logos', (e) => {
//     map.setLayoutProperty('route-logos', 'icon-size', 1);
//   });
  
  map.on('mouseleave', 'hiking-route', () => {
    map.getCanvas().style.cursor = '';
    popup.remove();
  });
  
  async function flyTo(left, bottom, right, top) {
    map.fitBounds([left, bottom, right, top], {speed: 0.5, padding: {top: window.innerHeight*0.03, bottom: window.innerHeight-$("#distance-container > .map-card").offset().top - $("#distance-container > .map-card").height() + window.innerHeight*0.03, left:  window.innerWidth*0.03, right:  window.innerWidth*0.03}});
  }
  
  async function setFilter(key) {
     map.setFilter("hiking-route-emph", [
  "all",
  [
    "match",
    ["get", key],
    ["yes"],
    true,
    false
  ]
]);
//      map.setFilter("poi-emph", [
//   "all",
//   [
//     "match",
//     [
//       "get",
//       key
//     ],
//     ["yes"],
//     true,
//     false
//   ],
//   [
//     "match",
//     ["get", "poi"],
//     ["hiking", "kajak"],
//     true,
//     false
//   ]
// ]);
  }
});

